//Actual API endpoints
export const TYPE = {
  LOGIN: "login",
  LOGOUT: "logout",
  CHANGE_PASSWORD: "change_password",
  GET_BOOKING_HISTORY: "get_booking_history",
  GET_PENDING_BOOKINGS: "get_pending_bookings",
  GET_BOOKINGS_BY_FILTERS: "get_bookings_by_filters",
  UPDATE_CHECKIN_STATUS: "update_checkin_status",
  DENY_BOOKING: "deny_booking",
  GET_SOLD_OUT_DATES: "get_sold_out_dates",
  CHECK_HOTEL_SOLD_OUT: "check_hotel_sold_out",
  UPDATE_SOLD_OUT_DATE: "update_sold_out_date",
  HOTEL_BULK_UNSOLD_OUT:"hotel_bulk_unsold_out",
  GET_HOTEL_ROOMS: "get_hotel_rooms",
  UPDATE_HOTEL_ROOMS: "update_hotel_rooms",
  GET_HOTEL_RATES: "get_hotel_rates",
  UPDATE_HOTEL_RATES: "update_hotel_rates",
  HOTEL_SOLD_OUT: "hotel_sold_out",
  HOTEL_BULK_UNSOLD_OUT:"hotel_bulk_unsold_out",
  GET_PROFILE_DATA: "get_profile_data",
  GET_AMENTIES: "get_amenities",
  GET_ADMIN_BOOKINGS: "get_admin_bookings_by_filters",
  GET_BOOKING_COUNT: "get_admin_bookings_count",
  GET_BOOKING_ID_DETAILS: "get_admin_booking_id_details",
  CANCEL_BOOKING: "cancel_booking",
  RESEND_COMMUNICATIONS: "resend_communications",
  UPDATE_CANCEL_CHECKIN: "update_cancel_checkin",
  UPDATE_CHECKIN_COMPLETE: "update_checkin_complete",
  UPDATE_AMENTIES: "update_amenity",
  POLICY_AGREE_DENY: "policy_agree_deny",
  SA_GET_BASIC_SETTINGS: "get_basic_settings",
  SHOW_BOOKINGS :"show_bookings",
  SA_UPDATE_HOTEL_SETTINGS: "update_hotel_settings",
  AUTH: "auth",
  SESSION_VALID: "SESSION_VALID",
  CLEAR_SESSION: "CLEAR_SESSION",
  GET_HOTEL_CHECKIN: "get_hotel_checkin",
  UPDATE_HOTEL_CHECKIN: "update_hotel_checkin",
  VERIFY_OTP_LOGIN: "verify_otp_login",
  GET_ADMIN_HOTELS: "get_admin_hotels",
  GET_ALL_MANAGERS: "get_all_managers",
  EXTRANET_ADD_MANAGER: "extranet_add_manager",
  EXTRANET_REMOVE_MANAGER: "extranet_remove_manager",
  GET_USERS_HOTELS:"get_users_hotels",
  SEND_OTP_TO_CHANGE_PASSWORD: "send_otp_to_change_password",
  CHANGE_PASSWORD_USING_OTP: "change_password_using_otp",
  GET_STATES: "get_states",
  GET_CITIES: "get_cities",
  GET_AREA: "get_areas",
  ADD_NEW_CITY: "add_new_city",
  ADD_NEW_AREA: "add_new_area",
  SEARCH_ADMIN: "search_admin",
  LINK_ADMIN_WITH_HOTEL: "link_admin_with_hotel",
  UPDATE_HOTEL: "update_hotel",
  GET_HOTEL_DETAILS: "get_hotel_details",
  GET_UNLISTED_HOTELS: "get_unlisted_hotels",
  WELCOME: "welcome",
  UPDATE_NEARBY_AREAS: "update_nearby_areas",
  UPLOAD_HOTEL_IMAGES: "upload_hotel_images",
  BOOKING_TRANSFER: "booking_transfer",
  ADD_UPDATE_CANCELLATION_COMMENT: "add_update_cancellation_comment",
  GET_CLOSED_HOTELS: "get_closed_hotels",
  ADD_NEW_ACCOUNT: "add_new_account",
  SEARCH_ACCOUNT: "search_account",
  LINK_ACCOUNT: "link_account",
  UNLINK_ACCOUNT: "unlink_account",
  UPLOAD_REG_DOCS: "upload_reg_docs",
  LINKED_ACCOUNT_HOTEL_LIST: "linked_account_hotel_list",
  ADMIN: "admin",
  GST: "gst",
  HOTEL_LIST_FINANCE: "hotel_list_finance",
  INITIAL_ENTRY: "initial_entry",
  GET_INVOICES: "get_invoices",
  LEDGER: "ledger",
  LEDGER_PAY_TRANSACTIONS: "ledger_pay_transactions",
  CREDIT_DEBIT_NOTE: "credit_debit_note",
  GET_DISPUTES: "get_disputes",
  RESOLVE_DISPUTE: "resolve_dispute",
  CREATE_DISPUTE: "create_dispute",
  GET_CD_NOTES: "get_cd_notes",
  DOWNLOAD: "download",
  DOWNLOAD_M: "download_m",
  HOTEL_FINANCE_SUMMARY: "hotel_finance_summary",
  GET_SALES_DATA: "get_sales_data",
  HIDE_HEADER:"hide_header",
  HIDE_FOOTER:"hide_footer",
  SET_TABVIEW:"set_tabview",
  GET_PACKAGE_BOOKING_HISTORY: "get_package_booking_history",
  GET_ADMIN_PACKAGE: "get_admin_package_by_filters",
  GET_PACKAGE_COUNT: "get_admin_package_count",
  RESEND_COMMUNICATIONS_PACKAGE: "resend_package_communications", //slim error, status code 500
  GET_PACKAGE_ID_DETAILs: "get_admin_package_id_details",
  UPDATE_PACKAGE_CANCEL_CHECKIN: "update_package_cancel_checkin",
  UPDATE_PACKAGE_CHECKIN_COMPLETE: "update_package_checkin_complete",
  CANCEL_PACKAGE: "cancel_package_booking",
  PACKAGE_CANCELLATION_COMMENT: "add_update_package_cancellation_comment",
  CREDIT_TRANSFER: "credit_transfer",
  HOTEL_PAYOUT: "hotel-payout",
  GET_EXTRANET_USER_BY_FILTERS: "get_extranet_user_by_filters",
  GET_USER_EXTRANET_PERMISSION_DETAILS: "get_user_extranet_permission_details",
  UPDTAE_USER_EXTRANET_PERMISSIONS: "update_user_extranet_permissions",
  LEDGER_PAY: "ledger-pay",
  LEDGER_PAY_M: "ledger-pay_m",
  // BOOKING_VOUCHER:'booking_voucher',
  ACTIVITY_LOGS: 'activity_logs',
  GET_S3_BUCKETS: 'bucket_list',
  GET_SPECIFIC_BUCKET: 'specific_bucket_list',
  UPLOAD_BUCKET_IMAGES: 'upload_bucket_images',
  UPLOAD_COUPON_IMAGES: "upload_coupon_images",
  CLICK_WRAP_AGR_ACCEPTANCE: "click_wrap_agr_acceptance",
  UPDATE_FOREIGN_GUEST_POLICY:"update_foreign_guest_policy",
  UPDATE_BULK_RANGE:"update_bulk_range",
  UPDATE_VIEW:"update_view",
  DELETE_DRAFT_HOTEL:"delete_draft_hotel",
  GET_PROMOTIONS: "get_promotions",
  EDIT_PROMOTIONS: "edit_promotions",
  GET_PROMOTIONS_ID_DETAILS: "get_promotion_id_details",
  INSERT_PROMOTION: "insert_promotion",
  UPDATE_PROMOTION: "update_promotion",
  COUPONS: {
    ENDPOINT: "coupons",
    PUT: "put_coupons",
    GET: "get_coupons",
    POST: "post_coupons",
  },
  SEO: {
    ENDPOINT: "seo",
    PUT: "put_seo",
    GET: "get_seo",
    POST: "post_seo"
  },
  SEO_CITIES: {
    ENDPOINT: "seo_cities",
    GET: "get_seo_cities",
  },
  SEO_CITY_CONTENT: {
    ENDPOINT: "seo_city_content",
    GET: "get_seo_city_content",
  },
  FAQS: {
    ENDPOINT: "faqs",
    PUT: "put_faqs",
    POST: "post_faqs",
  },
  USER_DETAILS: {
    ENDPOINT: "user_details",
    GET: "get_user_details",
  },
  WALLET_TRANSACTIONS: {
    ENDPOINT: "get_user_wallet_transactions",
    GET: "get_user_wallet_transactions",
  },
  USER_BY_MOBILE: {
    ENDPOINT: "get_user_by_mobile",
    GET: "get_user_by_mobile",
  },
  GET_REFUND_POLICIES:"get_refund_policies",
  GET_HOTEL_REFUND_POLICIES: {
    ENDPOINT:"get_hotel_refund_policies",
    POST:"get_hotel_refund_policies"
  },
  UPDATE_BULK_HOTEL_REFUND_POLICY: {
    ENDPOINT:"update_refund_policy",
    POST:"update_refund_policy"
  },
  GET_USERS_HOTEL:{
    ENDPOINT:"get_users_hotels",
    GET:"get_users_hotels"
  },
  UPDATE_MANAGER_DATA:{
    ENDPOINT:"update_manager_data",
    GET:"update_manager_data"
  },
  DRAFT_HOTELS: {
    ENDPOINT: "get_draft_hotels_of_user",
    POST: "get_draft_hotels_of_user"
  },
  UPDATE_OR_CREATE_DRAFT_HOTEL: {
    ENDPOINT: "create_or_update_draft_hotel",
    POST: "create_or_update_draft_hotel"
  },
  DRAFT_HOTEL_DETAILS: {
    ENDPOINT: "get_draft_hotel_details",
    POST: "get_draft_hotel_details"
  },
  GET_USER_REVIEW_FOR_HOTEL:"get_user_reviews_for_hotel",
  DOWNLOAD_BOOKING_DATA_EXTRANET:"download_booking_data_extranet",
  DOWNLOAD_LEDGER:"download_ledger",
  SET_REQ_BODY:"set_req_body",
  GET_PAST_PROPERTY_BOOKINGS:"get_past_property_bookings",
  GET_PENDING_PROPERTY_BOOKINGS:"get_pending_property_bookings",
  GET_PROPERTY_BOOKINGS_BY_FILTERS:"get_property_bookings_by_filters",
  GET_PROPERTY_BOOKINGS_DETAILS:"get_property_bookings_details",
  GET_FULL_DAY_ROOMS:"get-full-day-rooms",
  EDIT_FULL_DAY_ROOMS:"edit-full-day-rooms",
  BULK_EDIT_FULL_DAY:"bulk-edit-full-day",
  GET_ROOM_AMENITIES:"get_room_amenities",
  ADD_REMOVE_ROOM_AMENITIES: "add_remove_room_amenities",
  CREATE_ROOM:"create_room_category",
  GET_ROOM_CATEGORY_DATA:"get_room_category_data",
  GET_ROOM_IMAGES:"get_room_images",
  UPDATE_ROOM_CATEGORY:"update_room_category",
  GET_RATE_PLAN: "get_rate_plan",
  CREATE_RATE_PLAN:"create_rate_plan",
  UPDATE_RATE_PLAN:"update_rate_plan",
  UPDATE_PROPERTY_CHECKIN_CHECKOUT: "update_property_checkin_checkout",
  UPLOAD_ROOM_CATEGORY_IMAGES: "upload_room_category_img",
  SET_REQ_BODY:"set_req_body",
  DOWNLOAD_CLICK_WRAP_AGREEMENT:"download_click_wrap_agreement",
  GET_ROOM_PLAN_REFUND_POLICY:"get_room_plan_refund_policy",
  UPDATE_HOLIDA_CHECKIN_STATUS:"update_holida_checkin_status"
};


