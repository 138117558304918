import React from 'react';
import { Modal,Image } from 'semantic-ui-react';
import newExtranetImages from '../assets';
export default function ResponsePopup(props) {
  return (
    <Modal className="response-popup"
    open={props.isVisiblePopup}
    onOpen={() => props.setIsVisiblePopup(true)}
    onClose={() => props.setIsVisiblePopup(false)}
    // center={true}
    
  ><Modal.Content  style={{
    borderRadius: "0.75rem",
    display:"flex",justifyContent:"center",alignItems:"center",height:"100%",width:"100%"}}>
    <div>
    <Image className="response-illustration"
      src={
        props.isErrorPopup ? newExtranetImages.errorIllustration : newExtranetImages.successIllustration
      }
    />
    <p className='p_text p_text_xl p_text_bold'
    style={{textAlign:"center"}}
     >
      {props.popupMsg}
    </p>
    </div>
    </Modal.Content>
  </Modal>
  )
}
