import React, { useEffect, useRef, useState } from "react";

export default function CustomInputField(props) {
  const [isClicked, setIsCliked] = useState();
  let inputRef = useRef();

  const handleclick = () => {
    setIsCliked(true);
  };

  useEffect(() => {
    let handler = (e) => {
      if (isClicked) {
        if (!inputRef.current.contains(e.target)) {
          setIsCliked(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div
      ref={inputRef}
      className={`${props.className} ${
        props.isDisable ? "disabled" : isClicked ? "clicked" : ""
      }`}
      onClick={handleclick}
    >
      <input
        type="text"
        style={props.style}
        value={props.value}
        onChange={(e) => props.handleInputChange(e, props.inputFor)}
        placeholder={props.placeholder}
        disabled={props.isDisable}
      />
      {props.type && <span style={{ color: "#818181" }}>{props.type}</span>}
    </div>
  );
}
