import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";

export default function SingleDateCustomCalendar(props) {
  const [dateMonth, setDateMonth] = useState();
  const [dateDay, setDateDay] = useState();
  const [dateYear, setDateYear] = useState();
const [open,setOpen] = useState(false)
  const [openYearsDropDown, setOpenYearsDropdDown] = useState(false);
  const [openMonthsDropDown, setOpenMonthsDropDown] = useState(false);
  let calenderRef = useRef();
  let monthsRef = useRef();
  let yearsRef = useRef();

  useEffect(() => {
    resetDate();
  }, [props.date]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      if (calenderRef.current) {
        const modalRect = calenderRef.current.getBoundingClientRect();
        const isModalGoingOutside = modalRect.bottom > window.innerHeight;

        if (isModalGoingOutside) {
          calenderRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      }
      return () => (document.body.style.overflowY = "scroll");
    }
  }, [open]);

  useEffect(() => {
    let handler = (e) => {
      if (openMonthsDropDown) {
        if (!monthsRef.current.contains(e.target)) {
          setOpenMonthsDropDown(false);
        }
      } else if (openYearsDropDown) {
        if (!yearsRef.current.contains(e.target)) {
          setOpenYearsDropdDown(false);
        }
      } else if (open) {
        if (!calenderRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const mappedMonth = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const resetDate = () => {
    if (props.date == null || props.date == undefined || props.date == "") {
      setDateDay(moment().format("DD"));
      setDateMonth(moment().format("MMMM"));
      setDateYear(moment().format("YYYY"));
    } else {
      setDateDay(moment(props.date).format("DD"));
      setDateMonth(moment(props.date).format("MMMM"));
      setDateYear(moment(props.date).format("YYYY"));
    }
  };

  const years = () => {
    let yearsArr = [];
    for (let i = props.startYear? props.startYear : 2016; i < parseInt(moment().format("YYYY")) + 4; i++) {
      yearsArr.push(i);
    }
    return yearsArr;
  };

  const onSelectYear = (value, type) => {
    setDateYear(value);
    setOpenYearsDropdDown(false);
    setDateDay(1);
  };

  const onSelectMonth = (value, type) => {
    setDateMonth(value);
    setOpenMonthsDropDown(false);
    setDateDay(1);
  };

  const openDropDown = (type) => {
    if (type === "dateYear") {
      setOpenYearsDropdDown(!openYearsDropDown);
      setOpenMonthsDropDown(false);
    } else if (type === "dateMonths") {
      setOpenMonthsDropDown(!openMonthsDropDown);
      setOpenYearsDropdDown(false);
    }
  };

  const generateCalenderHeader = () => {
    let layout = [];

    layout.push(
      <div
        style={{
          height: 50,
          marginBlock: 10,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            cursor:
              props.restrictedPastDate &&
              moment(props.restrictedPastDate).format("YYYY") == dateYear &&
              mappedMonth[dateMonth] ==
                mappedMonth[moment(props.restrictedPastDate).format("MMMM")]
                ? "default"
                : "pointer",
          }}
          onClick={() =>
            props.restrictedPastDate &&
            moment(props.restrictedPastDate).format("YYYY") == dateYear &&
            mappedMonth[dateMonth] ==
              mappedMonth[moment(props.restrictedPastDate).format("MMMM")]
              ? null
              : onChangeMonth("previous")
          }
        >
          <svg
            style={{ marginTop: 15, marginInline: 10 }}
            width="0.938rem"
            height="1.125rem"
            viewBox="0 0 15 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 2.66669L2.84886 12.3178C2.38402 12.7827 2.39483 13.5396 2.87276 13.991L12.5 23.0834"
              stroke={
                props.restrictedPastDate &&
                moment(props.restrictedPastDate).format("YYYY") == dateYear &&
                mappedMonth[dateMonth] ==
                  mappedMonth[moment(props.restrictedPastDate).format("MMMM")]
                  ? "#818181"
                  : "#303030"
              }
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div
          tabIndex="0"
          ref={yearsRef}
          className={`m_yearsDropDown ${openYearsDropDown ? "open" : ""}`}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 1,
            marginTop: 7,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => openDropDown("dateYear")}
          >
            <div>
              <p
                className="m_date_filter_value"
                style={{
                  color: openYearsDropDown ? "#818181" : "#1c1c1c",
                  paddingLeft: openYearsDropDown ? 10 : 0,
                }}
              >
                {dateYear}
              </p>
            </div>

            <div
              className={`m_filtersDropDownIcon ${
                openYearsDropDown ? "open" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="m_arrow_icon_svg"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M6.71285 9.99541C7.11323 10.5064 7.88677 10.5064 8.28715 9.99541L14.4604 2.11677C14.9747 1.46043 14.5071 0.5 13.6733 0.5H1.32672C0.49291 0.5 0.025305 1.46044 0.53957 2.11677L6.71285 9.99541Z"
                  fill="#303030"
                />
              </svg>
            </div>
          </div>

          {openYearsDropDown && (
            <div className="m_filterOptionCls">
              {years().map((option, i) => {
                return (
                  <div
                    onClick={() => onSelectYear(option)}
                    className="m_years_options"
                    key={i}
                  >
                    <p
                      className="m_date_options_text"
                      style={{ color: "#1c1c1c" }}
                    >
                      {option}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          tabIndex="0"
          ref={monthsRef}
          className={`m_monthsDropDown ${openMonthsDropDown ? "open" : ""}`}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 1,
            // paddingTop: 5,
            marginLeft: 5,
            marginTop: 7,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => openDropDown("dateMonths")}
          >
            <div>
              <p
                className="m_date_filter_value"
                style={{
                  color: openMonthsDropDown ? "#818181" : "#1c1c1c",
                  paddingLeft: openMonthsDropDown ? 10 : 0,
                }}
              >
                {dateMonth}
              </p>
            </div>

            <div
              className={`m_filtersDropDownIcon ${
                openMonthsDropDown ? "open" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="m_arrow_icon_svg"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M6.71285 9.99541C7.11323 10.5064 7.88677 10.5064 8.28715 9.99541L14.4604 2.11677C14.9747 1.46043 14.5071 0.5 13.6733 0.5H1.32672C0.49291 0.5 0.025305 1.46044 0.53957 2.11677L6.71285 9.99541Z"
                  fill="#303030"
                />
              </svg>
            </div>
          </div>

          {openMonthsDropDown && (
            <div className="m_filterOptionCls">
              {months.map((option, i) => {
                return (
                  <div
                    onClick={() => onSelectMonth(option, "from")}
                    className="m_months_options"
                    key={i}
                  >
                    <p
                      className="m_date_options_text"
                      style={{ color: "#1c1c1c" }}
                    >
                      {option}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
           style={{
            cursor: notNullUndefined(props.restrictedFutureDate)
              ? moment(props.restrictedFutureDate).format("YYYY") == dateYear &&
                mappedMonth[dateMonth] ==
                mappedMonth[moment(props.restrictedFutureDate).format("MMMM")]
                ? "default"
                : "pointer"
              : "pointer",
          }}
          onClick={() =>
            notNullUndefined(props.restrictedFutureDate)
              ? moment(props.restrictedFutureDate).format("YYYY") == dateYear &&
                mappedMonth[dateMonth] ==
                mappedMonth[moment(props.restrictedFutureDate).format("MMMM")]
                ? null
                : onChangeMonth("next")
              : onChangeMonth("next")
          }
        >
          <svg
            style={{ marginTop: 15, marginInline: 10 }}
            width="0.938rem"
            height="1.125rem"
            viewBox="0 0 15 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66602 2.16663L12.3172 11.8178C12.782 12.2826 12.7712 13.0395 12.2933 13.4909L2.66602 22.5833"
              stroke={
                notNullUndefined(props.restrictedFutureDate)
                  ? moment(props.restrictedFutureDate).format("YYYY") ==
                    dateYear &&
                    mappedMonth[dateMonth] ==
                    mappedMonth[
                    moment(props.restrictedFutureDate).format("MMMM")
                    ]
                    ? "#818181"
                    : "#303030"
                  : "#303030"
              }
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    );

    return layout;
  };

  const onOpenCalender = () => {
    if (!props.isLoading) {
      resetDate();
      setOpen(true);
    }
  };

  const isDisableDate = (date) => {
    const dateToCheck = `${date}/${dateMonth}/${dateYear}`;
    if (
      props.restrictedFutureDate != undefined &&
      props.restrictedPastDate != undefined
    ) {
      return !(
        moment(dateToCheck).isBefore(moment(props.restrictedFutureDate)) &&
        !moment(dateToCheck).isBefore(moment(props.restrictedPastDate))
      );
    } else {
      if (props.restrictedFutureDate) {
        return !moment(dateToCheck).isBefore(
          moment(props.restrictedFutureDate)
        );
      }
      if (props.restrictedPastDate) {
        return moment(dateToCheck).isBefore(moment(props.restrictedPastDate));
      }
    }
    return false;
  }

  const closeModal = () => {
    setOpen(false);
  };


  const notNullUndefined = (value) => {
    return value != null && value != undefined;
  };
  
  const onChangeMonth = (type) => {
    let currentStateValueIndex = months.indexOf(dateMonth);
    setDateDay(1);
    if (type === "previous") {
      if (
        props.restrictedPastDate &&
        dateYear == moment(props.restrictedPastDate).format("YYYY") &&
        months.indexOf(moment(props.restrictedPastDate).format("MMMM")) ==
          months.indexOf(dateMonth) - 1
      ) {
        setDateDay(moment(props.restrictedPastDate).format("DD"));
      }
      if (currentStateValueIndex !== 0) {
        setDateMonth(months[currentStateValueIndex - 1]);
      } else {
        setDateMonth(months[11]);
        setDateYear(parseInt(dateYear) - 1);
      }
    } else {
      if (currentStateValueIndex !== 11) {
        setDateMonth(months[currentStateValueIndex + 1]);
      } else {
        setDateMonth(months[0]);
        setDateYear(parseInt(dateYear) + 1);
      }
    }
  };

  const generateWeekLabels = () => {
    let layout = [];

    layout.push(
      <Grid
        columns={7}
        style={{ paddingInline: "1.5rem", marginTop: "1.25rem" }}
      >
        <div
          style={{
            width: "14%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className="m_weekLabels">Mo</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">Tu</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">We</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">Th</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">Fr</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">Sa</h3>
        </div>

        <div
          style={{
            width: "14%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="m_weekLabels">Su</h3>
        </div>
      </Grid>
    );

    return layout;
  };

  const generateFromDateDays = () => {
    let startDayOfMonth = moment(`${dateYear}-${dateMonth}-01`).format("dddd");

    let startDayOfMonthValue = null;
    let currentDate = moment().format("D");
    let endOfTheMonth = parseInt(
      moment(`${dateYear}-${dateMonth}-01`).endOf("month").format("DD")
    );

    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    for (let i = 1; i < weekdays.length; i++) {
      if (startDayOfMonth == weekdays[i]) {
        startDayOfMonthValue = i;
      }
    }

    let fromDateDaysLayout = [];
    for (let i = 0; i < 42; i++) {
      if (i >= startDayOfMonthValue) {
        if (endOfTheMonth >= i - startDayOfMonthValue + 1) {
          fromDateDaysLayout.push(
            <div
              onClick={() =>
                isDisableDate(i - startDayOfMonthValue + 1)
                    ? null
                    : onSelectDate(i - startDayOfMonthValue + 1)
              }
              tabIndex="0"
              className={`${
                isDisableDate(i - startDayOfMonthValue + 1)
                  ? "disableDayLabels"
                  : dateDay == i - startDayOfMonthValue + 1
                  ? "m_selectedDay"
                  : "m_daysLabel"
              }`}
              style={{
                height: "3.2rem",
                borderRadius: "50%",
                width: "14%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3
                className="m_txt m_txt_bold"
                style={{
                  cursor:
                  isDisableDate(i - startDayOfMonthValue + 1)
                      ? "not-allowed"
                      : "pointer",
                  color:
                  isDisableDate(i - startDayOfMonthValue + 1)  ? "#818181"
                      : dateDay == i - startDayOfMonthValue + 1
                      ? "#fff"
                      : "",
                  fontSize: "1.125rem",
                }}
              >
                {i - startDayOfMonthValue + 1}
              </h3>
            </div>
          );
        }
      } else {
        fromDateDaysLayout.push(
          <div
            style={{
              height: "3.2rem",
              borderRadius: "50%",
              width: "14%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        );
      }
    }

    return fromDateDaysLayout;
  };

  const onSelectDate = (date) => {
    setDateDay(date);
    props.setDate(
      moment(
        `${dateYear}-${
          months.indexOf(dateMonth) < 9
            ? `0${months.indexOf(dateMonth) + 1}`
            : months.indexOf(dateMonth) + 1
        }-${date < 10 ? `0${date}` : date}`
      ).format("YYYY-MM-DD")
    );
    setOpen(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          gap: "0.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.header ? (
          <div onClick={() => (props.isLoading ? null : onOpenCalender())}>
            {props.header}
          </div>
        ) : (
          <div
            className="m_cp_calenderBlock"
            style={{ height: "2.5rem" }}
            onClick={() => (props.isLoading ? null : onOpenCalender())}
          >
            <h3
              className="m_txt m_txt_md m_txt_bold"
              style={{
                color:
                  props.date === null ||
                  props.date === undefined ||
                  props.date === ""
                    ? "#818181"
                    : "",
              }}
            >
              {props.date === null ||
              props.date === undefined ||
              props.date === ""
                ? "Select Date"
                : `${moment(props.date).format("Do MMM YYYY")}`}
            </h3>
          </div>
        )}
      </div>
      {open ? (
        <div className="m_modalView" onClick={() => closeModal()} />
      ) : null}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {open ? (
          <div
            style={{ display: "flex", justifyContent: props.alignCalender?props.alignCalender:"center", width: "100%" }}
          >
            <div
              ref={calenderRef}
              className={props.className ? props.className : "m_date-view cp"}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <p
                  className="m_txt m_txt_sm m_txt_regular"
                  style={{ color: "#818181" }}
                >
                  {props.title}
                </p>
              </div>
              {generateCalenderHeader()}

              {generateWeekLabels()}

              <Grid
                columns={7}
                style={{
                  paddingInline: "1.6rem",
                  paddingBlock: "1rem",
                }}
              >
                {generateFromDateDays()}
              </Grid>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
