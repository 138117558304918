import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loader, Grid } from "semantic-ui-react";
import Layout from './hoc/layout';
import Auth from './hoc/auth'

import {
  isMobile
} from "react-device-detect"
import newExtranetImages from "./assets";
import Lottie from "react-lottie-player";
import ChangePassword from './components/NewUI/managePassword';
import CreateEditPromotion from "./components/Promotions/CreateEditPromotion";

const Login = React.lazy(() => import('./components/Login'));
const PageNotFound = React.lazy(() => import('./components/views/pageNotFound'))
const PolicyUpdate = React.lazy(() => import('./components/PolicyUpdate'));
const HotelRegister = React.lazy(() => import('./components/RegisterHotel'))
const AdminRegistration = React.lazy(() => import('./components/AdminRegistration'))
const BulkAccountSearch = React.lazy(() => import('./components/bulkAccountSearch'))
const BulkAdminSearch = React.lazy(() => import('./components/bulkAdminSearch'))
// const ExperienceBooking = React.lazy(() => import('./components/ExperienceBooking'))
const Payment = React.lazy(() => import("./components/Payment"));
const AgreementNew = React.lazy(() => import('./components/NewUI/Agreement'));



const Profile1 = React.lazy(() => import('./components/NewUI/Profile'));
const Amenities1 = React.lazy(() => import('./components/NewUI/Amenities'));
const Staff1 = React.lazy(() => import('./components/NewUI/Staff'));
const ManageRooms1 = React.lazy(() => import('./components/NewUI/ManageRooms'));
const HotelFinance1 = React.lazy(() => import('./components/NewUI/HotelFinance'));
const Bookings1 = React.lazy(() => import('./components/NewUI/Bookings'));
const Policy1 = React.lazy(() => import('./components/NewUI/HotelPolicy'));
const hotelProperty1 = React.lazy(() => import('./components/NewUI/hotelProperty'));
const helpSupport1 = React.lazy(() => import('./components/NewUI/HelpNSupport'));
const changePassword1 = React.lazy(() => import('./components/NewUI/managePassword'));
const Home = React.lazy(() => import('./components/NewUI/Home'));
const IntialPage = React.lazy(() => import('./components/NewUI/IntialPage'));
const LedgerMobile = React.lazy(() => import('./components/Hotel_Finance/tabsGeneration/ledgerTab_m'))
const InvoiceMobile = React.lazy(() => import('./components/Hotel_Finance/tabsGeneration/invoiceTab_m'))
const SalesDataMobile = React.lazy(() => import('./components/Hotel_Finance/tabsGeneration/salesDataTab_m'))
const Summary = React.lazy(() => import('./components/Hotel_Finance/tabsGeneration/summary_m'))
const BulkUpdateRateRooms = React.lazy(() => import('./components/ManageInventory/bulkUpdateRateRooms'))
const EditHotelData = React.lazy(() => import('./components/ManageInventory/editHotelRateRooms'))
const BulkUpdateSoldOut = React.lazy(() => import('./components/ManageInventory/bulkUpdateSoldOut'))
const BulkTimingsUpdate = React.lazy(() => import('./components/ManageInventory/bulkTimingsUpdate'))
const BulkUpdateView = React.lazy(() => import('./components/ManageInventory/bulkUpdateViewMobile'))
const BulkUpdateData = React.lazy(() => import('./components/ManageInventory/updateBulkData'))
const BulkSoldOut_m = React.lazy(() => import('./components/ManageInventory/bulkSoldOut_m'))
const HotelDetails = React.lazy(() => import('./components/Profile/hotelDetails_m'))
const HotelBankDetails = React.lazy(() => import('./components/Profile/hotelBankDetails_m'))
const HotelPolicy = React.lazy(() => import('./components/Profile/hotelPolicy_m'))
const HotelReviews = React.lazy(() => import('./components/Profile/hotelReviews_m'))
const HotelSummary = React.lazy(() => import('./components/HotelSummary/hotelSumary_m'))
const HotelProperties = React.lazy(() => import('./components/Profile/properties_m'))
const AddStaffMemberMobileView = React.lazy(() => import("./components/UserAccess/addStaffMember_m.js"))
const EditStaffMemberMobileView = React.lazy(() => import("./components/UserAccess/editStaffDetails_m"))
const HotelLandingPage = React.lazy(() => import('./components/LandingPage'))
const SearchedBookings = React.lazy(() => import('./components/Bookings/SearchBookings/SearchedBookings'))
const HotelSupport = React.lazy(() => import("./components/LandingPage/cantactPage"))
const HotelFAQ = React.lazy(() => import("./components/LandingPage/FAQ"))
const HotelGettingStartedGuide = React.lazy(() => import("./components/LandingPage/gettingStarted"))
const HotelNotes = React.lazy(() => import("./components/Hotel_Finance/tabsGeneration/notesTab_m"))
// const HotelListing = React.lazy(()=>import('./components/hotelListing'));
const DraftHotels = React.lazy(() => import('./components/DraftHotels'))
const ForeignGuestNew = React.lazy(() => import('./components/NewUI/ForeignGuestModal'))
const ServerErrorMobile = React.lazy(() => import('./components/views/serverErrorPage'))
const GettingStartedWeb = React.lazy(() => import("./components/NewUI/GettingStarted"))
const Rooms_FlexiUI = React.lazy(() => import("./components/NewUI/Rooms_FlexiUI"))
const UnAuthorized = React.lazy(() => import("./components/UnAuthorized"))
const SessionTimeout = React.lazy(() => import("./components/SessionTimeout"))
const HolidaSearchedBookings = React.lazy(() => import("./components/HolidaBookingsMobile/SearchBookings/holidaSearchedBookings"))
const FulldayInventory = React.lazy(() => import("./components/FullDayInventory"))
const RegisterRoomCategory = React.lazy(() => import("./components/CreateRoomCategory"));
const RoomCategory = React.lazy(() => import("./components/RoomCategory"))
const InventoryHome = React.lazy(() => import("./components/MobileInventory"))
const HolidaInventory = React.lazy(() => import("./components/FullDayInventoryMobile"));
const HolidaBulkUpdate = React.lazy(() => import("./components/FullDayInventoryMobile/BulkUpdateView.js"));
const webInventoryHome = React.lazy(() => import("./components/InventoryHome"));
const BookingsWebView = React.lazy(() => import("./components/BookingsWebView/index.js"));
const RatePlanComponentMobile = React.lazy(() => import("./components/RoomCategoryMobile/ratePlanComponent_m"))
const HotelSummaryWeb = React.lazy(() => import("./components/HotelSummaryWeb/index.js"))
const AuthApiErrorComponent = React.lazy(() => import("./hoc/authApiErrorComponent.js"))
const Promotion = React.lazy(() => import("./components/Promotions"));
const EditOrCreatePromotion = React.lazy(() => import("./components/Promotions/CreateEditPromotion"))

const Routes = () => {
  return (
    <Layout>
      <Suspense fallback={
        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>

          <Lottie
            loop={true}
            animationData={newExtranetImages.Loader}
            play
            style={{
              width: 50,
              height: 50,
            }}
          />
          <p
            style={{
              cursor: "pointer",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "30px",
              color: "#3E3E3E",
            }}
          >
            Loading...
          </p>

        </div>
      }
      >
        <Switch>
          <Route path="/bookings" exact component={Auth(BookingsWebView, true)} />
          <Route path="/hotelfinance" exact component={Auth(HotelFinance1, true)} />
          <Route path="/profile" exact component={Auth(Profile1, true)} />
          <Route path="/amenities" exact component={Auth(Amenities1, true)} />
          <Route path="/policy" exact component={Auth(Policy1, true)} />
          <Route path="/password-change" exact component={Auth(changePassword1, true)} />
          <Route path="/inventory" exact component={Auth(webInventoryHome, true)} />
          <Route path="/holida-inventory" exact component={Auth(HolidaInventory, true)} />
          <Route path="/holida-inventory/bulk-update" exact component={Auth(HolidaBulkUpdate, true)} />
          <Route path="/inventory-home" exact component={Auth(InventoryHome, true)} />
          <Route path="/full-day-inventory" exact component={Auth(FulldayInventory, true)} />
          <Route path="/manage-rooms/room-slots" exact component={Auth(Rooms_FlexiUI, true)} />
          <Route path="/user-access" exact component={Auth(Staff1, true)} />
          <Route path="/new-user/support/FAQ" exact component={Auth(HotelFAQ, true)} />
          <Route path="/help-support" exact component={Auth(helpSupport1, true)} />
          <Route path="/help-support/faqs" exact component={Auth(helpSupport1, true)} />
          <Route path="/hotel-property" exact component={Auth(hotelProperty1, true)} />
          <Route path="/login" exact component={Auth(Login, false)} />
          <Route path="/getting-started-guide" exact component={Auth(GettingStartedWeb, true)} />
          <Route path="/bookings/searched-bookings" exact component={Auth(SearchedBookings, true)} />
          <Route path="/bookings/holida-searched-bookings" exact component={Auth(HolidaSearchedBookings, true)} />
          <Route path="/policy-update" exact component={Auth(PolicyUpdate, true)} />
          {/* <Route path="/register-hotel/:id" exact component={Auth(HotelRegister, true)} /> */}
          {/* <Route path="/bulk-admin-search" exact component={Auth(BulkAdminSearch, true)} /> */}
          {/* <Route path="/admin-registration" exact component={Auth(AdminRegistration, true)} /> */}
          {/* <Route path="/bulk-account-search" exact component={Auth(BulkAccountSearch, true)} /> */}
          <Route path="/payment/:error" exact component={Payment} />
          <Route path="/" exact component={Auth(HotelSummaryWeb, true)} />
          <Route path="/agreement" exact component={Auth(AgreementNew, true)} />
          <Route path="/foreign-guest" exact component={Auth(ForeignGuestNew, true)} />
          <Route path="/hotel-finance/ledger" exact component={Auth(LedgerMobile, true)} />
          <Route path="/hotel-finance/invoice" exact component={Auth(InvoiceMobile, true)} />
          <Route path="/hotel-finance/sales" exact component={Auth(SalesDataMobile, true)} />
          <Route path="/hotel-finance/summary" exact component={Auth(Summary, true)} />
          <Route path="/inventory/update-rooms-and-rates" exact component={Auth(BulkUpdateRateRooms, true)} />
          <Route path="/inventory/update-bulk-soldout" exact component={Auth(BulkUpdateSoldOut, true)} />
          <Route path="/inventory/update-timings" exact component={Auth(BulkTimingsUpdate, true)} />
          <Route path="/inventory/bulk-update-view" exact component={Auth(BulkUpdateView, true)} />
          <Route path="/inventory/bulk-update-data" exact component={Auth(BulkUpdateData, true)} />
          <Route path="/inventory/bulk-sold-out" exact component={Auth(BulkSoldOut_m, true)} />
          <Route path="/profile/hotel-details" exact component={Auth(HotelDetails, true)} />
          <Route path="/profile/hotel-bank-details" exact component={Auth(HotelBankDetails, true)} />
          <Route path="/profile/hotel-policy" exact component={Auth(HotelPolicy, true)} />
          <Route path="/profile/hotel-properties" exact component={Auth(HotelProperties, true)} />
          <Route path="/profile/hotel-reviews" exact component={Auth(HotelReviews, true)} />
          <Route path="/inventory/edit-hotel-data" exact component={Auth(EditHotelData, true)} />
          <Route path="/add-staff-member" exact component={Auth(AddStaffMemberMobileView, true)} />
          <Route path="/edit-staff-details/:id" exact component={Auth(EditStaffMemberMobileView, true)} />
          <Route path="/summary" exact component={Auth(HotelSummaryWeb, true)} />
          <Route path="/new-user_m" exact component={Auth(HotelLandingPage, true)} />
          <Route path="/draft-hotels" exact component={Auth(DraftHotels, true)} />
          <Route path="/new-user" exact component={Auth(IntialPage, true)} />
          <Route path="/hotel-finance/notes" exact component={Auth(HotelNotes, true)} />
          <Route path="/new-user/support" exact component={Auth(HotelSupport, true)} />
          <Route path="/server-error" exact component={Auth(ServerErrorMobile, true)} />
          <Route path="/new-user/support/gettingStarted" exact component={Auth(HotelGettingStartedGuide, true)} />
          <Route path="/create-room-category" exact component={Auth(RegisterRoomCategory, true)} />
          <Route path="/room-category/:id" exact component={Auth(RoomCategory, true)} />
          <Route path="/rate-plan/:rc_id/:rp_id" exact component={Auth(RatePlanComponentMobile, true)} />
          <Route path="/unauthorized" exact component={Auth(UnAuthorized, true)} />
          <Route path="/session-timeout" exact component={SessionTimeout} />
          <Route path="/internal-server-error" exact component={Auth(AuthApiErrorComponent,true)}/>
          <Route path="/promotions" exact component={Auth(Promotion,true)}/>
          <Route path="/create-promotion/:id" exact component={Auth(CreateEditPromotion,true)}/>
          <Route component={Auth(PageNotFound, true)} />
        </Switch>
      </Suspense>
    </Layout>
  );

}
export default Routes;


